import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import { Container, Row, Button } from "react-bootstrap";

const HowWorks = () => {
  return (
    <section className="py-5 py-md-8 bg-primary">
      <Container>
        <Row>
          <Col xl={6} className="px-md-3 mb-3 pt-3 text-center text-xl-start">
            <h2 className="text-white pb-1 mb-0 fs-2 fs-lg-1">How it works?</h2>
            <p className="text-white mt-3"> Your time-efficient path to influence!</p>
            <Button
              className="cta-btn text-white px-6 py-2 w-100 w-md-auto mt-4"
              variant="secondary"
              target="_self"
              rel="noreferrer"
              href={"/contact"}
            >
              {"Contact"}
            </Button>
          </Col>
          <Col xl={6} className="px-md-3 mb-3 pt-3 text-start">
            <div class="items">
              <div className="light-backgorund p-4 mb-3">
                <h6 className="text-white fs-5">1.Initial Strategy Session <span className="fs-6">(60 minutes, one-time call)</span> </h6>
                <ul className="mt-3 ps-3 pt-1">
                  <li className="text-white">Identify your unique expertise areas </li>
                  <li className="text-white">Define your target audience and objectives </li>
                  <li className="text-white">Establish your authentic voice and perspective </li>
                  <li className="text-white">Create a three-month content roadmap </li>
                </ul>
              </div>
              <div className="light-backgorund p-4 mb-3">
                <h6 className="text-white fs-5">2. Monthly Content Capture <span className="fs-6 ps-0">(30 minutes)</span> </h6>
                <p className="text-white fs-6 pt-2">Choose your preferred method: </p>
                <ul className="mt-3 ps-3">
                  <li className="text-white">Brief voice notes  </li>
                  <li className="text-white">Short scheduled call  </li>
                  <li className="text-white">Written responses to targeted questions  </li>
                  <li className="text-white">Quick review of existing materials  </li>
                </ul>
              </div>
              <div className="light-backgorund p-4 mb-3">
                <h6 className="text-white fs-5">3. Content Development <span className="fs-6 ps-0">(no time required from you)</span> </h6>
                <ul className="mt-3 ps-3 pt-1">
                  <li className="text-white">Professional transformation of your insights   </li>
                  <li className="text-white">Industry research and trend analysis   </li>
                  <li className="text-white">Creation of both short-form and long-form content   </li>
                  <li className="text-white">Strategic integration of keywords and topics   </li>
                </ul>
              </div>
              <div className="light-backgorund p-4 mb-3">
                <h6 className="text-white fs-5">4. Approval Process  <span className="fs-6 ps-0">(30 minutes)</span> </h6>
                <ul className="mt-3 ps-3 pt-1">
                  <li className="text-white">Review draft content at your convenience    </li>
                  <li className="text-white">Quick feedback incorporation    </li>
                  <li className="text-white">Final approval before publication    </li>
                </ul>
              </div>
              <div className="light-backgorund p-4 mb-3">
                <h6 className="text-white fs-5">5. Publication & Management  <span className="fs-6 ps-0">(fully handled for you)</span> </h6>
                <ul className="mt-3 ps-3 pt-1">
                  <li className="text-white">Strategic posting schedule     </li>
                  <li className="text-white">Platform optimisation     </li>
                  <li className="text-white">Hashtag strategy     </li>
                  <li className="text-white">Engagement monitoring      </li>
                </ul>
                <p className="text-white fs-6 pt-2 mb-0">Total monthly time investment: Just ONE hour </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HowWorks;
