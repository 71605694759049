import { useStaticQuery, graphql, Link } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import ServiceHeroSmall from "../../components/service-hero-small";
import CtaBanner from "../../components/cta-banner";
import Navigation from "../../components/navigation";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import ServiceCard2 from "../../components/service-card2";
import HowWorks from "../../components/how-works";
import PhotoRight from "../../components/photo-right";


const ExecutiveThoughtLeadershipPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Translation-Hero" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      socialImg: wpMediaItem(title: { eq: "Transform Your Career" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      websiteCopyImg: wpMediaItem(title: { eq: "Translation-Services" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      blogWritingImg: wpMediaItem(title: { eq: "Unleash Your Message" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      advertisingImg: wpMediaItem(title: { eq: "Expand Your Reach" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      digitalImg: wpMediaItem(title: { eq: "Build Trust" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      blog2Img: wpMediaItem(title: { eq: "Leave A Lasting Legacy" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      ayrImg: wpMediaItem(title: { eq: "Home-Hero" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      whyARCImg: wpMediaItem(title: { eq: "Why-Choose-ARC" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
      testimonialImg: wpMediaItem(title: { eq: "testimonial" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
  const ayrImg = data.ayrImg?.localFile.childImageSharp.gatsbyImageData;
  const whyARCImg = data.whyARCImg.localFile.childImageSharp.gatsbyImageData;
  const testimonialImg = data.testimonialImg.localFile.childImageSharp.gatsbyImageData;
  
  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Services",
        item: {
          url: `${siteUrl}/services`,
          id: `${siteUrl}/services`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "ExecutiveThoughtLeadershipPage",
        item: {
          url: `${siteUrl}/services/executive-thought-leadershipPage`,
          id: `${siteUrl}/services/executive-thought-leadershipPage`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Navigation />
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title="ExecutiveThoughtLeadershipPage"
        description="Unlock your brand’s potential with an experienced non-fiction ghostwriter and transform yourself from a business leader to a thought leader"
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/services/executive-thought-leadershipPage`,
          title: "ExecutiveThoughtLeadershipPage",
          description:
            "Unlock your brand’s potential with an experienced non-fiction ghostwriter and transform yourself from a business leader to a thought leader",
          images: [
            {
              url: `https://arc-writing.rjmdigital.net/wp-content/uploads/2021/11/copywriting-services-scaled-1.jpg`,
              width: 1621,
              height: 2560,
              alt: "Ghostwriting services in London",
            },
          ],
        }}
      />
      <section className="pt-5 pb-5 pb-lg-0 pt-lg-6">
        <Container>
          <Row>
            <Col className="text-center">
              <h1 className="underline fs-1 d-none d-md-inline-block">Executive Thought Leadership</h1>
              <h1
                className="pt-3 mb-1 d-md-none"
                
              >
                Executive Thought Leadership
              </h1>
            </Col>
          </Row>
        </Container>
      </section>

      <ServiceHeroSmall
        imgWidth="16rem"
        image={heroImg}
        imgAlt={data.heroImg.altText}
        heading="Your expertise. Your voice. Just one hour monthly."
        text={
          <>
            <p>
              In today's competitive business landscape, your voice as a C-suite
              leader matters more than ever. But between strategic planning,
              stakeholder management and operational oversight, who has time to
              maintain a consistent thought leadership presence?
            </p>
            <p className="fw-bold">
              Introducing my Executive Thought Leadership service: where your
              insights are transformed into influential content with just one
              hour of your time each month.
            </p>
          </>
        }
      />
  
      <section className="py-5 pb-8">
        <Container className="text-center">
          <h2 className="mb-7 underline fs-2 fs-lg-1 d-none d-md-inline-block">
            Why thought leadership matters for executives
          </h2>
          <h2 className="mb-7 fs-2 fs-lg-1 d-md-none">
            Why thought leadership matters for executives
          </h2>
          <Row className="justify-content-center">
            <ServiceCard2
              title="Stakeholder Confidence"
              text="Greater stakeholder confidence in your strategic vision "
            />
            <ServiceCard2
              title="Professional Reputation"
              text="Enhanced professional reputation across your industry and beyond."
              isBackground={true}
            />
            <ServiceCard2
              title="Business Development"
              text="Increased business development opportunities through enhanced visibility "
              isTabletdisplay={true}
            />
            <ServiceCard2
              title="Talent Attraction"
              text="Improved talent attraction as professionals seek visionary leaders "
              isBackground={true}
              isTabletdisplay={true}
            />
            <ServiceCard2
              title="Industry Influence"
              text="Expanded industry influence that opens doors to new opportunities ."
              isBackground={true}
              isMobiledisplay={true}
            />
          </Row>
        </Container>
      </section>
      <HowWorks />
      <section className="py-5 py-md-8">
        {" "}
        <div className="text-center">
          <h2 className="mb-5 underline d-none d-md-inline-block fs-2 fs-lg-1 text-center">
            The executive dilemma: solved 
          </h2>
        </div>
        <div Tag="section" className=" position-relative   ">
          <div
            style={{ zIndex: "-3" }}
            className="bg-light-background d-lg-none h-100 w-100 position-absolute"
          ></div>
          <Container className="pt-lg-4 ">
            <Row className="  px-lg-3 pt-lg-5 justify-content-end">
              <Col lg={10}>
                <div className="position-relative ">
                  <div
                    style={{ zIndex: "-3" }}
                    className="bg-light-background d-none d-md-block h-100 w-100 position-absolute"
                  ></div>
                  <div className="position-absolute top-20 d-none d-lg-block start-0 translate-left-x">
                    <GatsbyImage
                      style={{ maxWidth: "20rem", height: "15rem" }}
                      image={ayrImg}
                      alt="Are you ready to begin your journey as an author?"
                    />
                  </div>

                  <Col
                    lg={{ span: 9, offset: 3 }}
                    xl={{ span: 8, offset: 3 }}
                    className="px-xl-3 ps-lg-4 pe-lg-4 py-6 "
                  >
                    <GatsbyImage
                      style={{ maxWidth: "40px", height: "40px", objectFit: 'contain' }}
                      image={testimonialImg}
                      imgStyle={{ objectFit: "contain"}}
                      alt="Are you ready to begin your journey as an author?"
                    />
                    <h4 className="pt-3 mb-3 pb-1 fs-2 fst-italic">
                      I know thought{" "}
                      <span className="fw-bold">leadership matters,</span> but I
                      simply don't have the time.
                    </h4>

                    <p className="mb-lg-0 fw-light pt-2">
                      This is precisely why I created this Executive Thought
                      Leadership service. 
                    </p>
                    <p className="fw-light">
                      Our unique approach captures your insights efficiently and
                      transforms them into compelling content that establishes
                      your authority without disrupting your schedule. 
                    </p>
                    <p className="mb-lg-0 fw-light">
                      <span className="fw-bold">The result?</span> A powerful
                      professional presence that works for you while you focus
                      on running your business. 
                    </p>
                  </Col>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
  
      <section className="py-5 py-lg-0">
        <Container>
          <Row>
            <Col>
              <div className="text-center">
                <h2 className="mb-7 underline d-none d-md-inline-block fs-2 fs-lg-1 text-center">
                  Why choose my Executive <br />
                  Thought Leadership service?
                </h2>
              </div>
              <h3 className=" mb-4 fs-4 fatface">
                Language expertise that sets me apart
              </h3>
              <p className="mb-0">
                With nearly two decades of experience in professional language
                services, I bring unique capabilities to executive
                communications:
              </p>
              <ul
                style={{ fontWeight: "600" }}
                className="copy-list ps-0  py-3"
              >
                <li className="fw-bold">
                  Multilingual understanding of English, French, Italian and
                  Spanish 
                </li>
                <li className="fw-bold">
                  Advanced skills in distilling complex ideas for specific
                  audiences 
                </li>
                <li className="fw-bold">
                  Cultural sensitivity for international executive positioning 
                </li>
                <li className="fw-bold">
                  Qualified member of the Institute of Translation and
                  Interpreting (ITI) 
                </li>
                <li className="fw-bold">
                  Author of the business book 'Copy that!' chronicling my
                  professional journey  from translator to copywriter and
                  ghostwriter 
                </li>
              </ul>
              <h3 className=" mb-4 fs-4 fatface">Results you can expect </h3>
              <p className="mb-0">My clients typically experience: </p>
              <ul
                style={{ fontWeight: "600" }}
                className="copy-list ps-0  py-3"
              >
                <li className="fw-bold">
                  Consistent, high-quality thought leadership presence without
                  time sacrifice 
                </li>
                <li className="fw-bold">
                  Growing recognition as an authority in their field  
                </li>
                <li className="fw-bold">
                  Increased engagement on professional platforms 
                </li>
                <li className="fw-bold">
                  New business and partnership opportunities through enhanced
                  visibility 
                </li>
                <li className="fw-bold">
                  More speaking opportunities on radio/TV/podcasts 
                </li>
                <li className="fw-bold">
                  Professional reputation growth that extends beyond their
                  organisation 
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5 py-lg-7">
        <Container>
        <Row className="mb-7">
						<Col>
							<div className="text-center">
								<h2 className="underline d-none d-md-inline-block  mb-4 fs-2 fs-lg-1 ">
                  Service packages
								</h2>
								<h2 className="fs-2 fs-lg-1 mb-3 underline d-md-none">Service packages</h2>
							</div>
						</Col>
					</Row>
          <Row className="h-100 justify-content-center g-5 g-lg-4">
            <Col style={{ minHeight: "100%" }} lg={4}>
              <div
                className="w-100 bg-white h-100 p-4 position-relative d-flex flex-column"
                style={{ boxShadow: "0px 3px 20px #00000029" }}
              >
                <h2 className="pb-0 mb-1 fs-3 text-uppercase">
                    ESSENTIAL
                </h2>
                <hr
                  className="w-100 mt-0 pt-0"
                  style={{ opacity: 1, backgroundColor: "#C157A1" }}
                />
                <ul
                  style={{ fontWeight: "600" }}
                  className="copy-list ps-0  py-4"
                >
                  <li>1 long-form article monthly </li>
                  <li>4 supporting LinkedIn posts </li>
                  <li>Basic engagement monitoring </li>
                  <li>Monthly strategy adjustment </li>
                </ul>
                
                <div className="d-none d-lg-block bottom-0 w-100 pt-5 mt-auto">
                  <hr
                    className="w-100 mt-0 pt-0 mb-4"
                    style={{ opacity: 0.7, backgroundColor: "#00000029" }}
                  />
                  <p className="fatface text-center">
                    <span className="fs-3">£1000 + VAT</span>
                    <span style={{ color: "#3D3D3D76" }} className="fs-4">
                      /month
                    </span>
                  </p>
                </div>
                <div className="d-lg-none">
                  <hr
                    className="w-100 mt-0 pt-0 mb-4"
                    style={{ opacity: 0.7, backgroundColor: "#00000029" }}
                  />
                  <p className="fatface pb-3 text-center">
                    <span className="fs-3">£1000 + VAT</span>
                    <span style={{ color: "#3D3D3D76" }} className="fs-4">
                      month
                    </span>
                  </p>
                </div>
              </div>
            </Col>
            <Col style={{ minHeight: "100%" }} lg={4}>
              <div
                className="w-100 bg-white h-100 p-4 position-relative d-flex flex-column"
                style={{ boxShadow: "0px 3px 20px #00000029" }}
              >
                <h2 className="pb-0 mb-1 fs-3 text-uppercase">
                  Professional
                  
                </h2>
                <hr
                  className="w-100 mt-0 pt-0"
                  style={{ opacity: 1, backgroundColor: "#C157A1" }}
                />
                <ul
                  style={{ fontWeight: "600" }}
                  className="copy-list ps-0  py-4"
                >
                  <li>2 long-form articles monthly </li>
                  <li>8 supporting LinkedIn posts </li>
                  <li>Comprehensive engagement tracking </li>
                  <li>Content repurposing strategy </li>
                  <li>Monthly performance report </li>
                </ul>
                <div className="d-none d-lg-block bottom-0 w-100 pt-5 mt-auto">
                  <hr
                    className="w-100 mt-0 pt-0 mb-4"
                    style={{ opacity: 0.7, backgroundColor: "#00000029" }}
                  />
                  <p className="fatface text-center">
                    <span className="fs-3">£1750 + VAT</span>
                    <span style={{ color: "#3D3D3D76" }} className="fs-4">
                      /month
                    </span>
                  </p>
                </div>
                <div className="d-lg-none">
                  <hr
                    className="w-100 mt-0 pt-0 mb-4"
                    style={{ opacity: 0.7, backgroundColor: "#00000029" }}
                  />
                  <p className="fatface text-center">
                    <span className="fs-3">£1750 + VAT</span>
                    <span style={{ color: "#3D3D3D76" }} className="fs-4">
                      /month
                    </span>
                  </p>
                </div>
              </div>
            </Col>
            <Col style={{ minHeight: "100%" }} lg={4}>
              <div
                className="w-100 bg-white h-100 p-4 position-relative d-flex flex-column"
                style={{ boxShadow: "0px 3px 20px #00000029" }}
              >
                <h2 className="pb-0 mb-1 fs-3 text-uppercase">
                  Executive
                </h2>
                <hr
                  className="w-100 mt-0 pt-0"
                  style={{ opacity: 1, backgroundColor: "#C157A1" }}
                />
                <ul
                  style={{ fontWeight: "600" }}
                  className="copy-list ps-0  py-4"
                >
                  <li>4 long-form articles monthly  </li>
                  <li>Daily LinkedIn presence management  </li>
                  <li>Advanced analytics and reporting  </li>
                  <li>Cross-platform content strategy  </li>
                  <li>Priority scheduling  </li>
                </ul>
                <div className="d-none d-lg-block bottom-0 w-100 pt-5 mt-auto">
                  <hr
                    className="w-100 mt-0 pt-0 mb-4"
                    style={{ opacity: 0.7, backgroundColor: "#00000029" }}
                  />
                  <p className="fatface text-center">
                    <span className="fs-3">£2500 + VAT</span>
                    <span style={{ color: "#3D3D3D76" }} className="fs-4">
                      /month
                    </span>
                  </p>
                </div>
                <div className="d-lg-none">
                  <hr
                    className="w-100 mt-0 pt-0 mb-4"
                    style={{ opacity: 0.7, backgroundColor: "#00000029" }}
                  />
                  <p className="fatface pb-3 text-center">
                    <span className="fs-3">£2500 + VAT</span>
                    <span style={{ color: "#3D3D3D76" }} className="fs-4">
                      /month
                    </span>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-6">
            <Col xl={8} className="text-center">
              <div className="d-flex align-items-center justify-content-center">
                <p className="fw-bold mb-0">Bespoke packages are available on request.<Link
										className={`pink-link cta-link fw-bold ps-1`}
										to={'/contact'}
                    style={{ borderBottom: '1px solid #C157A1'}}
									>
										Get in Touch
									</Link></p>
                
              </div>
              
            </Col>
          </Row>
        </Container>
      </section>
      <CtaBanner
        headline={<>Ready to establish your executive voice? </>}
        bgColor="dark-background"
        btnTxt="Book your discovery call"
        btnLink="/contact"
        btn2Txt="Request information here"
        btn2Link="/contact"
        desc={
          <>
            <p>Schedule a no-obligation discovery call to discuss how my Executive Thought Leadership service can be tailored to your specific needs and professional goals. </p>
          </>
        }
      />
      <section className="py-5 pt-lg-5 pt-lxl0 py-md-0">
				<PhotoRight
					imgWidth="30rem"
					image={whyARCImg}
					imgAlt={data.whyARCImg.altText}
					title="Executive Thought Leadership"
					text={
						<>
							<p>
              Recent research shows executive content receives 8× more engagement than corporate channels, with 82% of decision-makers influenced by thought leadership when evaluating potential partners. 
							</p>
							<p className="fw-bold">
              Yet only 15% of C-suite executives rate their thought leadership efforts as excellent. 
							</p>
						</>
					}
          btnTxt="Contact"
          btnLink="/contact"
				/>
			</section>
    </Layout>
  );
};

export default ExecutiveThoughtLeadershipPage;
