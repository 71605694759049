import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";

const ServiceCard2 = ({
  title,
  text,
  isBackground,
  isMobiledisplay,
  isTabletdisplay,
}) => {
  return (
    <>
      {isMobiledisplay && (
        <Col
          xl={4}
          md={6}
          className="px-md-3 mb-3 pt-3 text-start d-block d-md-none"
        >
          <div className={`border border-light-background p-3 h-100`}>
            <h3 className="normal-font fs-3 fw-bold">{title}</h3>
            <hr
              className="bg-primary"
              style={{ height: "5px", width: "150px", opacity: "1" }}
            />
            <p>{text}</p>
          </div>
        </Col>
      )}
      {isTabletdisplay && (
        <Col
          xl={4}
          md={6}
          className="px-md-3 mb-3 pt-3 text-start d-none d-md-block d-xl-none"
        >
          <div
            className={`border border-light-background p-3 h-100 ${
              !isBackground ? "bg-light-background" : ""
            }`}
          >
            <h3 className="normal-font fs-3 fw-bold">{title}</h3>
            <hr
              className="bg-primary"
              style={{ height: "5px", width: "150px", opacity: "1" }}
            />
            <p>{text}</p>
          </div>
        </Col>
      )}
      <Col
        xl={4}
        md={6}
        className={`px-md-3 mb-3 pt-3 text-start ${
          isMobiledisplay
            ? "d-none d-md-block"
            : isTabletdisplay
            ? "d-md-none d-xl-block"
            : ""
        }`}
      >
        <div
          className={`border border-light-background p-3 h-100 ${
            isBackground ? "bg-light-background" : ""
          }`}
        >
          <h3 className="normal-font fs-3 fw-bold">{title}</h3>
          <hr
            className="bg-primary"
            style={{ height: "5px", width: "150px", opacity: "1" }}
          />
          <p>{text}</p>
        </div>
      </Col>
    </>
  );
};

export default ServiceCard2;
